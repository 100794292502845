import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
//import "../styles/mystyles.scss"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"

const SecondPage = ({ data }) => (
  <Layout showFrontendLinks={true}>
    <Seo title="Shakespeare in Bits" />
    <div className="container">
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1 className="title">Select a Play</h1>
          </div>
        </div>
      </nav>
      <div className="columns is-centered">
        <div className="column is-one-third">
          <Link to="raj/scenes">
            <div className="card fixed-height">
              <header className="card-header">
                <p className="card-header-title">Romeo & Juliet</p>
              </header>
              <div className="card-image">
                <GatsbyImage
                  image={data.raj_banner.childImageSharp.gatsbyImageData}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt=""
                />
              </div>
              <div className="card-content">
                <div className="content">
                  Immerse yourself in Shakespeare's timeless story of love,
                  vengeance and tragedy - a tragic tale of two star-cross'd
                  lovers whose untimely deaths finally unite two feuding
                  families.
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="column is-one-third">
          <Link to="macbeth/scenes">
            <div className="card fixed-height">
              <header className="card-header">
                <p className="card-header-title">Macbeth</p>
              </header>
              <div className="card-image">
                <GatsbyImage
                  image={data.macbeth_banner.childImageSharp.gatsbyImageData}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt=""
                />
              </div>
              <div className="card-content">
                <div className="content">
                  Read, watch and listen to one of Shakespeare's most
                  widely-studied plays as Shakespeare In Bits brings to life the
                  tragic tale of the murderous Macbeth in one complete
                  experience.
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="column is-one-third">
          <Link to="msnd/scenes">
            <div className="card fixed-height">
              <header className="card-header">
                <p className="card-header-title">A Midsummer Night's Dream</p>
              </header>
              <div className="card-image">
                <GatsbyImage
                  image={data.msnd_banner.childImageSharp.gatsbyImageData}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt=""
                />
              </div>
              <div className="card-content">
                <div className="content">
                  A royal wedding in Athens sends four young lovers and a motley
                  crew of amateur actors into a fairy-filled woodland where they
                  fall under impish manipulation. Could this be real or is it a
                  dream after all?
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-one-third">
          <Link to="hamlet/scenes">
            <div className="card fixed-height">
              <header className="card-header">
                <p className="card-header-title">Hamlet</p>
              </header>
              <div className="card-image">
                <GatsbyImage
                  image={data.hamlet_banner.childImageSharp.gatsbyImageData}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt=""
                />
              </div>
              <div className="card-content">
                <div className="content">
                  Experience one of Shakespeare's most powerful and influential
                  tragedies as Shakespeare In Bits brings you on a journey of
                  deception, revenge, treachery, indecision and moral decay
                  through the fabled and tragic character of Hamlet.
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="column is-one-third">
          <Link to="jc/scenes">
            <div className="card fixed-height">
              <header className="card-header">
                <p className="card-header-title">Julius Caesar</p>
              </header>
              <div className="card-image">
                <GatsbyImage
                  image={data.jc_banner.childImageSharp.gatsbyImageData}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt=""
                />
              </div>
              <div className="card-content">
                <div className="content">
                  Travel back in time to ancient Rome and join the intrigue as a
                  group of disgruntled citizens plot a conspiracy.
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    raj_banner: file(relativePath: { eq: "playicons/raj_banner.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    macbeth_banner: file(relativePath: { eq: "playicons/macbeth_banner.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    msnd_banner: file(relativePath: { eq: "playicons/msnd_banner.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    hamlet_banner: file(relativePath: { eq: "playicons/hamlet_banner.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
    jc_banner: file(relativePath: { eq: "playicons/jc_banner.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, layout: CONSTRAINED)
      }
    }
  }
`

export default SecondPage
